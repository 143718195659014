import { Card, Group, Box, Title, Paper, Loader } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import PropTypes from "prop-types";

export const DashboardCard = ({ title, icon, content, value, loading }) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="lg"
      withBorder
      style={{ width: "100%", maxWidth: 400 }}
      sx={{
        "@media (max-width: 768px)": {
          padding: "md",
          width: "100%",
        },
      }}
    >
      <Group justify="space-between">
        <Paper
          w={35}
          h={35}
          display="flex"
          radius="xl"
          bg="gray.1"
          mb="sm"
        >
          <Box ml={7} mt={7} pos={"absolute"}>{icon}</Box>
        </Paper>
        <IconDots
          style={{
            transform: "rotate(90deg)",
            marginBottom: "2rem",
            width: "5%",
            height: "5%",
            color: "gray",
          }}
        />
      </Group>
      <Group justify="space-between" mt="md" mb="xs">
        <Title tt={"uppercase"} order={2} fw="bold">
          {title}
        </Title>
      </Group>
      {loading ? (
                  <Loader size="sm" />
            ) : ( 
            <Title order={3} tt={"uppercase"} c={""}>
            {value} {content}
            </Title>
            )
        }
    </Card>
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  value: PropTypes.number,
  content: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
