import { useEffect, useState } from "react";
import http from "../plugins/axios";

export const useDashboard = (role, date) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const formatDate = (date) => {
        if (!date) return null;
        const parsedDate = new Date(date);
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
        return `${year}-${month}`;
    };

    const fetchDasboard = async () => {
        try {
            const fromDate = formatDate(date[0]);
            const toDate = formatDate(date[1]);
            setLoading(true)
            const response = await http.get(role === "superadmin" ? `/dashboard/superadmin?fromDate=${fromDate}&toDate=${toDate}` : `/dashboard/admin`)
            setData(response.data.data)
        } catch (error) {
            console.log("error", + error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (date && date[0] && date[1]) {
            fetchDasboard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, role]);


    return {
        data,
        loading
    }
}