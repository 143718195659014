import React, {useEffect, useState}from "react";
import Sidebar from "../../ui/AppShell/Sidebar";
import { Box, Group, Text, Card, Title } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DashboardCard } from "./Card";
import { AdminDashboardContent, SuperAdminDashboardContent } from "../../../constant";
import { useDashboard } from "../../../hooks";
import { useSelector } from "react-redux";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const Dashboard = () => {
  const dataUser = useSelector((state) => state.auth.dataUser);
  const [value, setValue] = useState([null, null]);
  const { data, loading } = useDashboard(dataUser?.roleAlias[0], value);

  const chartData = data.organizationChart?.labels.map((label, index) => ({
    month: label,
    organization: data.organizationChart.data[index],
  }));

  useEffect(() => {
    const startOfYear = new Date(new Date().getFullYear(), 0, 1);
    const now = new Date();
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    setValue([startOfYear, endOfLastMonth]);
}, []);

  const content =
    dataUser?.roleAlias[0] === "superadmin"
      ? SuperAdminDashboardContent
      : AdminDashboardContent;

  const concatData = content.map((item, index) => {
    const value = Object.values(data)[index];
    return {
      ...item,
      value,
    };
  });

  return (
    <Sidebar>
      <Box w={"100%"} p="md">
        <Text fw={700} mb="lg" fz="lg">
          Welcome to SSO Admin!
        </Text>
        <Group
          display={"flex"}
          flex={"wrap"}
          justify={dataUser?.roleAlias[0] === "superadmin" ? "flex-start" : "space-between"}
          dir={"row"}
          gap={"xl"}
          sx={{
            "@media (max-width: 768px)": {
              flexDirection: "column",
              gap: "md",
            },
          }}
        >
          {concatData.map((item, index) => (
            <DashboardCard
              key={index}
              title={item.name}
              icon={item.icon}
              value={item.value}
              content={item.content}
              loading={loading}
            />
          ))}
        </Group>
        {dataUser?.roleAlias[0] === "superadmin" ?
        <Card
          shadow="sm"
          padding="lg"
          radius="lg"
          mt={20}
          withBorder
          style={{minWidth: "100%"}}
          sx={{
            "@media (max-width: 768px)": {
              padding: "md",
              width: "100%",
            },
          }}
        >
          <Box mt="xl">
            <Group justify="space-between" mb={10} mx={35}>
              <Title tt={"uppercase"} fw={700} ml={40} fz="lg">
                Organization Chart
              </Title>
              <DatePickerInput type="range" value={value} onChange={setValue} />
            </Group>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line 
                  isAnimationActive={true}
                  animationDuration={1000} 
                  type="natural" 
                  dataKey="organization" 
                  stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Card>
        : null}
      </Box>
    </Sidebar>
  );
};

export default Dashboard;
