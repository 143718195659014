import { IconUsers, IconCirclesFilled, IconAppsFilled, IconSitemap } from "@tabler/icons-react"

const user = <IconUsers style={{width: "80%", height: "80%", color: "#339af0"}}/>
const organization = <IconSitemap style={{width: "80%", height: "80%", color: "#339af0"}}/>
const role = <IconCirclesFilled style={{width: "80%", height: "80%", color: "#20c997"}}/>
const app = <IconAppsFilled style={{width: "80%", height: "80%", color: "#fcc419"}}/>

export const AdminDashboardContent = [
    {
        name: "Total Users",
        icon: user,
        content: "Users"
    },
    {
        name: "Total App",
        icon: app,
        content: "Apps"
    },
    {
        name: "Total Role",
        icon: role,
        content: "Roles"
    }
]

export const SuperAdminDashboardContent = [
    {
        name: "Total Organization",
        icon: organization,
        content: "Organization"
    },
    {
        name: "Total App",
        icon: app,
        content: "Apps"
    }
]