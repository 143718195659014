import React, { useState } from "react";
import Cookies from "universal-cookie";
import http from "../../plugins/axios.js";
import { validation } from "../../plugins/validation";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { update } from "../../store/authData.js";
import { updatedRoute } from "../../store/permissionRoute.js";
import { getPermissionRole, login } from "../../services/auth.js";
import { Tooltip } from "@mantine/core";
import { useMediaQuery} from "@mantine/hooks";
import { notificationError, notificationSuccess } from "../../components/ui/Notification/index.jsx";

// mantine ui & image
import classes from "./authentication.module.css";
import { Anchor, Badge, Box, Button, Card, Group, Image, PasswordInput, Text, TextInput, rem } from "@mantine/core";
import Logo from "../../assets/img/logo tandeem.png";

const initialVal = {
  nickname: "",
  username: "",
  password: "",
};

const formValidation = {
  nickname: {
    isError: false,
    message: "",
  },
  username: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
};

const Auth = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [errMessage, setErrMessage] = useState(null);
  const isMobile = useMediaQuery("(max-width: 430px)");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationForm(formValidation);

    const isErrorForm = validation(formData, setValidationForm);
    if (isErrorForm) {
      setLoading(false);
      return;
    }
    try {
      const response = await login(formData);
      if (response) {
        notificationSuccess("Login Berhasil", `Selamat datang ${response.fullName}`);
        http.defaults.headers.common["Authorization"] = `Bearer ${response.access_token}`;
        const dataLogin = {
          fullName: response.fullName,
          id: response.id,
          username: response.username || response.email,
          organization: response.organization,
          // access_token: response.access_token,
          role: response.role,
          roleAlias: response.roleAlias,
          scope: response.scope,
          user: response.user,
        };
        cookies.set("authData_SSO", dataLogin, { path: "/" });
        cookies.set("token", response.access_token, { path: "/" });
        cookies.set("refreshToken", response.refresh_token, { path: "/" });
        getHandlePermissionRole();
        dispatch(update({ dataUser: response, isLogin: true }));
        navigate("/dashboard");
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setErrMessage(errorMessage || "");
      notificationError("Login Gagal", `${errorMessage ? errorMessage : "Silahkan periksa kembali form anda"}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoading(false);
    }
  };

  const getHandlePermissionRole = async () => {
    try {
      const response = await getPermissionRole();
      dispatch(updatedRoute({ access: response }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.card}>
        <Card shadow="sm" padding="lg" w={isMobile ? 280 : 400}>
          <Box className={classes.logo}>
            <Image src={Logo} alt="logo" />
          </Box>

          <Group mt="md" mb="xs">
            <Text fw={400}>Masukkan nickname, email atau username, dan password untuk sign-in!</Text>
          </Group>

          <Box>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box mb={20}>
                <Box mb="sm">
                  <TextInput
                    name="nickname"
                    label="Organization Nickname"
                    placeholder="Masukkan organization nickname"
                    error={validationForm.nickname.isError ? `${validationForm.nickname.message}` : ""}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
                <Box mb="sm">
                <TextInput
                  name="username"
                  label="Email atau Username"
                  placeholder="Masukkan email atau username"
                  error={validationForm.username.isError ? `${validationForm.username.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
                </Box>
                <Box mb="sm">
                  <PasswordInput
                    name="password"
                    placeholder="Masukkan Password"
                    label="Password"
                    error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
                <Box>
                  <Group mb="xs" justify="right">
                    <Anchor
                      size="sm"
                      fw={800}
                      c="blue"
                      className={classes.textLink}
                      href="/authentication/sign-in/forgot-password"
                    >
                      Lupa Password?
                    </Anchor>
                  </Group>
                </Box>
                {errMessage && (
                  <Box mb="sm" ta="center">
                    <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                      {errMessage}
                    </Badge>
                  </Box>
                )}
              </Box>
              <Box>
                <Tooltip 
                  position={"bottom"}
                  disabled={
                    formData.nickname &&
                    formData.password && 
                    formData.username
                  } 
                  label="Please Complete The Form">
                    <Group grow>
                      <Button
                        className={classes.button}
                        disabled={
                          !formData.nickname || 
                          !formData.password || 
                          !formData.username ||
                          validationForm.nickname.isError ||
                          validationForm.password.isError ||
                          validationForm.username.isError
                        }
                        variant="light"
                        radius="sm"
                        size="md"
                        loading={loading}
                        color="#0572b9"
                        type="submit"
                      >
                        Sign In
                      </Button>
                    </Group>
                  </Tooltip>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Auth;
