import React, { useEffect, useState } from "react";
import classes from "./user.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import TableSkeleton from "../../components/ui/TableSkeleton";
import TableUsers from "../../components/pages/user/TableUser";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import FormUser from "../../components/pages/user/FormUser";
import { getListUser } from "../../services/users";
import { useDebounce } from "use-debounce";

const defaultParameter = {
  skip: 0,
  take: 10,
  search: "",
  applicationId: "",
  orderBy: "fullName",
  order: "ASC",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama",
    width: "auto",
  },
  {
    label: "Email",
    width: "auto",
  },
  {
    label: "Username",
    width: "auto",
  },
  {
    label: "Role",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const User = () => {
  const [params, setParams] = useState(defaultParameter);
  const [usersList, setUsersList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const [searchVal] = useDebounce(params.search, 500, {trailing: true});

  const handleGetUsersList = async () => {
    try {
      setLoading(true);
      const response = await getListUser(params);
      setUsersList(response?.[0]);
      setCount(response?.[1]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUsersList();
  // eslint-disable-next-line
  }, [searchVal]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      search: e.target.value,
      applicationId: "",
      orderBy: "fullName",
      order: "ASC",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      search: "",
      applicationId: "",
      orderBy: "fullName",
      order: "ASC",
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Users Management
        </Text>

        <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <TextInput leftSectionPointerEvents="none" placeholder="Cari User. . ." name="search" leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />} onChange={handleSearchChange} />
          </Flex>
          <Flex mt={isSmallScreen ? 10 : 0}>
            <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
              Tambah User
            </Button>
          </Flex>
        </Box>
        <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableUsers label={labelTable} data={usersList} countData={params.skip} actionMethod={handleAction} />}</Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
        </Flex>
      </Box>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit User" : "Tambah User"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormUser dataUser={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetUsersList} />
      </Modal>
    </Sidebar>
  );
};

export default User;
