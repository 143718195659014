export const validation = (formData, setValidationForm) => {
  let isError = false;

  Object.keys(formData).forEach((field) => {
    if (!formData[field] && field !== "isActive" && field !== "isForAll") {
      setValidationForm((old) => ({
        ...old,
        [field]: {
          isError: true,
          message: "Form tidak boleh kosong",
        },
      }));
      isError = true;
    } else {
      setValidationForm((old) => ({
        ...old,
        [field]: {
          isError: false,
          message: "",
        },
      }));
    }
  });

  return isError;
};
