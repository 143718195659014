import React from "react";
import { Table, Badge, Menu, ActionIcon, Text } from "@mantine/core";
import { IconDotsVertical, IconEye } from "@tabler/icons-react";

const ActionMenu = ({ handleAction }) => {
  return (
    <Menu width={200} shadow="sm" position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant="transparent" size="sm" color="rgba(0, 0, 0, 1)">
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconEye stroke={1.5} size={18} />} onClick={() => handleAction("edit")} fw="600" fz={12}>
          View Detail / Edit
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const TableUsers = ({ label, data, countData, actionMethod }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) =>
    data.length !== 0 ?
      data.map((val, index) => {
        return (
          <Table.Tr key={index}>
            <Table.Td>{index + countData + 1}.</Table.Td>
            <Table.Td>{val.fullName}</Table.Td>
            <Table.Td>{val.email}</Table.Td>
            <Table.Td>{val.username}</Table.Td>
            <Table.Td>
              {val?.userRole?.length === 0
                ? val?.role?.name || "-"
                : val.userRole?.map((el) => {
                    return (
                      <Text fz={13}>
                        {el?.role?.applicationRole?.[0]?.application?.name}, {el?.role?.name || "-"}
                      </Text>
                    );
                  })}
            </Table.Td>
            <Table.Td>
              <Badge size="md" radius="sm" variant="filled" color={val.isActive ? "green" : "red"}>
                {val.isActive ? "Active" : "Non Active"}
              </Badge>
            </Table.Td>
            <Table.Td>
              <ActionMenu handleAction={(type) => actionMethod(val, type)} />
            </Table.Td>
          </Table.Tr>
        );
      })
      : (
        <Table.Tr>
            <Table.Td py={15} colSpan={label.length} align="middle" style={{fontWeight: "bold"}}>{"Data Not found"}</Table.Td>
        </Table.Tr>
      );

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table horizontalSpacing="sm" withTableBorder style={{ fontSize: "13px" }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{mappingDataTable(data)}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableUsers;
